import React, { createContext, useState } from "react";

interface DataProps {
  username?: string;
  password?: string;
}

interface Props {
  update: (data: DataProps) => void;
  clear: () => void;
  data: DataProps
}

export const RegistrationContext = createContext<Props>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  update: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clear: async () => {},
  data: {}
});

export const RegistrationProvider = ({ children }) => {
  const [data, update] = useState<DataProps>({});

  const clear = () => {
    update({})
  }

  return (
    <RegistrationContext.Provider
      value={{ update, clear, data }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};
