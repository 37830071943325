export const decodeCookies = () => {
  const cookieParts = document.cookie.split(";");
  const cookies = {};

  for (let index = 0; index < cookieParts.length; index++) {
    const cookie = cookieParts[index],
      equals_pos = cookie.indexOf("="),
      name = unescape(cookie.slice(0, equals_pos)).trim();
    cookies[":" + name] = unescape(cookie.slice(equals_pos + 1));
  }
  return cookies;
};

export const findCookieByName = (search: string) => {
  const cookies = decodeCookies();

  for (const name in cookies) {
    const value = cookies[name];
    if (name.indexOf(":" + search) == 0) {
      return value;
    }
  }
};
