import React, { createContext, useEffect, useState } from "react";
import { globalHistory } from "@reach/router";

export interface SeoLanguageAlternative {
  uri: string;
  locale?: string;
}

export interface SeoProperties {
  title?: string;
  titleOverride?: boolean;
  description?: string;
  images?: {
    banner?: string;
    logo?: string;
  };
  noIndex?: boolean;
  structuredData?: any;
  languageAlternatives?: SeoLanguageAlternative[]
}

interface SeoContextInterface {
  data: SeoProperties;
  setData: (props: SeoProperties) => void;
}

export const SeoContext = createContext<SeoContextInterface>({
  data: {},
  setData: () => {},
});

export const SeoProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<SeoProperties>({});

  useEffect(() => {
    // Reset SEO when routing (pages are responsible for setting the correct data)
    return globalHistory.listen(() => {
      setData({});
    });
  }, []);

  return (
    <SeoContext.Provider value={{ data, setData }}>
      {children}
    </SeoContext.Provider>
  );
};
