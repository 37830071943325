import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { meQuery, UserDto, UserDtoType } from "../dtos/user.dto";
import { Persistency } from "../utils/persistency";
import { apiBaseUrl } from "../utils/api";
import { ResetPasswordUserDto } from "../dtos/reset-password.dto";

interface RegisterRequest {
  username: string;
  email: string;
  password: string;
  profileName: string;
  twitchUsername?: string;
  youtubeUsername?: string;
  birthday?: string
}

interface ChangePasswordRequest {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface LoginResponse extends ErrorResponse {
  jwt?: string;
  user?: UserDto;
}

export interface IAuthWithJwtResponse {
  jwt: string;
  user: {
    id: string;
  };
}

interface UpdateResponse {
  balanceChange: number;
}

export interface ErrorResponse {
  message?: Array<{
    messages: Array<{ id: string; message?: string }>;
  }>;
}

export interface IAuthContext {
  jwt?: string | null;
  userId?: string | null;
  user?: UserDto | null;
  register?: (request: RegisterRequest) => Promise<LoginResponse>;
  changePassword?: (request: ChangePasswordRequest) => Promise<Response>;
  handleAuthResponse?: (
    response: Response | ResetPasswordUserDto
  ) => Promise<Response | ResetPasswordUserDto>;

  setAuth?: (data: IAuthWithJwtResponse) => void;

  callback?: (type: string) => Promise<LoginResponse>;
  update?: () => Promise<UpdateResponse>;
  logout?: () => void;
  isPartner?: boolean;
  isStreamer?: boolean;
}

export const AuthContext = React.createContext<IAuthContext>({});

export const AuthProvider: React.FC = ({ children }) => {
  const persistencyKeys = {
    jwt: "auth:jwt",
    userId: "auth:user-id",
    profileName: "auth:profile-name",
  };

  const [jwt, setJwt] = useState<string | null>();
  const [userId, setUserId] = useState<string | null>();
  const [initialized, setInitialized] = useState<boolean>();

  const getAuthenticationHeaders = async () => {
    const jwtToken = Persistency.get("auth:jwt");

    return {
      Authorization: jwtToken ? `Bearer ${jwtToken}` : "",
    };
  };

  const { data, loading, error, refetch } = useQuery<{ user: UserDto }>(
    meQuery,
    {
      variables: { id: userId },
      skip: !userId,
    }
  );

  const callback = async (type: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get("access_token");

    const response = await fetch(
      `${apiBaseUrl()}/auth/${type}/callback?access_token=${accessToken}`,
      {
        method: "GET",
      }
    );

    return await handleAuthResponse(await response.json());
  };

  const handleAuthResponse = async (
    response: LoginResponse | ResetPasswordUserDto
  ) => {
    if (response.jwt) {
      Persistency.set(persistencyKeys.jwt, response.jwt);
      Persistency.set(persistencyKeys.userId, response.user?.id as string);

      setJwt(response.jwt);
      setUserId(response.user?.id as string);

      location.reload();
    }

    return response;
  };

  const register = async (request: RegisterRequest) => {
    if (!request.birthday) delete request.birthday

    const response = await fetch(`${apiBaseUrl()}/auth/local/register`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...request,
      }),
    });

    return await handleAuthResponse(await response.json());
  };

  const changePassword = async (request: ChangePasswordRequest) => {
    const response = await fetch(`${apiBaseUrl()}/auth/change-password`, {
      method: "POST",
      headers: {
        ...(await getAuthenticationHeaders()),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...request,
      }),
    });

    return response.ok ? response : response.json();
  };

  const logout = () => {
    Persistency.remove(persistencyKeys.jwt);
    Persistency.remove(persistencyKeys.userId);
    Persistency.set(persistencyKeys.profileName, 'guest')

    setJwt(null);
    setUserId(null);

    location.reload();
  };

  const update = async () => {
    const balance = data?.user?.balance || 0;
    const response = await refetch();

    if (response.data) {
      const newBalance = response.data.user.balance || 0;
      const balanceChange = newBalance - balance;

      if (balanceChange > 0) {
        return { balanceChange };
      }
    }

    return { balanceChange: 0 };
  };

  const setAuth = (data: IAuthWithJwtResponse) => {
    Persistency.set(persistencyKeys.jwt, data.jwt);
    Persistency.set(persistencyKeys.userId, data.user?.id);

    setJwt(data.jwt);
    setUserId(data.user?.id);

    location.reload();
  };

  const isPartner = data?.user?.type === UserDtoType.Partner;
  const isStreamer = data?.user?.type === UserDtoType.Streamer;

  useEffect(() => {
    setJwt(Persistency.get(persistencyKeys.jwt));
    setUserId(Persistency.get(persistencyKeys.userId));

    setInitialized(!error);
  }, []);

  const allowedErrors = [
    /'[a-zA-Z]+\.headers'/
  ];

  useEffect(() => {
    if (userId && error && !allowedErrors.find(r => r.test(error.toString()))) {
      Persistency.remove(persistencyKeys.jwt);
      Persistency.remove(persistencyKeys.userId);
      Persistency.set(persistencyKeys.profileName, 'guest')

      location.reload();
    }

    window.addEventListener("storage", ({ key }) => {
      if (key === persistencyKeys.userId) {
        location.reload();
      }
    });
  }, [error]);

  useEffect(() => {
    const hasUserId = Persistency.get(persistencyKeys.userId) !== null;
    const hasProfileName = Persistency.get(persistencyKeys.profileName) !== null;

    if (data?.user?.profileName) {
      Persistency.set(persistencyKeys.profileName, data!.user.profileName)
    }

    // Set initial value
    if (!hasProfileName) Persistency.set(persistencyKeys.profileName, 'guest')
    // Backwards compatability
    if (hasUserId && !hasProfileName) location.reload()
  }, [data?.user?.profileName])

  useEffect(() => {
    !!userId && refetch({ id: userId });
  }, [userId]);

  return (
    <AuthContext.Provider
      value={{
        jwt,
        userId,
        user: data?.user,
        setAuth,
        callback,

        register,
        changePassword,
        handleAuthResponse,
        update,
        logout,
        isPartner,
        isStreamer,
      }}
    >
      {initialized && (
        <div className={loading ? "cloaked" : ""}>{children}</div>
      )}
    </AuthContext.Provider>
  );
};
