import React, { useContext, useEffect, useState } from "react";
import {
  NotificationDto,
  notificationMarkReadAllMutation,
  notificationMarkReadMutation,
  notificationsQuery,
} from "../dtos/notification.dto";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { AuthContext } from "./auth.context";
import { generateVersion } from "../utils/generate-version";
import { scrollLock } from "../utils/scroll-lock";

interface NotificationsContextInterface {
  version: string;
  data: NotificationDto[] | undefined;
  loadMore: () => Promise<any>;
  count?: number;
  loading?: boolean;
  error?: ApolloError;
  menu: boolean;
  toggleMenu: () => Promise<any>;
  markRead: (id: string, close: boolean) => Promise<any>;
  markReadAll: () => Promise<any>;
}

export const NotificationsContext =
  React.createContext<NotificationsContextInterface>({
    version: "",
    data: [],
    loadMore: async () => {},
    count: 0,
    loading: false,
    menu: false,
    toggleMenu: async () => {},
    markRead: async () => {},
    markReadAll: async () => {},
  });

export const NotificationsProvider: React.FC = ({ children }) => {
  const authContext = useContext(AuthContext);
  const [data, setData] = useState<NotificationDto[] | undefined>();
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [menu, setMenu] = useState<boolean>(false);
  const [version, setVersion] = useState<string>(generateVersion());
  const [markReadMutation] = useMutation(notificationMarkReadMutation);
  const [markReadAllMutation] = useMutation(notificationMarkReadAllMutation);
  const limit = 10;

  // Count query definition
  // Poll for new unread notifications in fixed time window
  const countQuery = useQuery(
    gql`
      query NotificationsUnreadCount {
        notificationsUnreadCount
      }
    `,
    {
      pollInterval: authContext.userId ? 5000 : 0,
    }
  );

  // Data query definition
  // Skip when menu is closed
  const query = useQuery(notificationsQuery, {
    variables: { limit },
    skip: !menu,
  });

  // Fetches more data
  const fetch = async (start: number, limit: number) => {
    return query.fetchMore({
      variables: { limit, start },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        setData([
          ...(start === 0 || !data ? [] : data),
          ...fetchMoreResult.notificationsCurrent,
        ]);

        // Re-render external components by generating new version
        setVersion(generateVersion());

        return prev;
      },
    });
  };

  // Allows infinite scroll to fetch more data
  const loadMore = async () => {
    if (data) fetch(data.length, 10);
  };

  // Sets loading state to active
  const startLoading = async () => setLoading(true);

  // Sets loading state to inactive, after a fixed timeout
  const stopLoading = async () =>
    setTimeout(() => {
      setLoading(false);
    }, 500);

  // Opens and closes menu depending on the state
  const toggleMenu = async () => {
    scrollLock(!menu);
    setMenu(!menu);

    // Load when menu opens
    if (!menu) {
      startLoading();
      fetch(0, limit).then(() => {
        stopLoading();
      });
    }
  };

  // Marks single notification as read
  // Closes the menu
  const markRead = async (id: string, close = true) => {
    markReadMutation({ variables: { id: id } });
    countQuery.refetch();
    if (close) return toggleMenu();
  };

  // Marks all notifications as read
  const markReadAll = async () => {
    await markReadAllMutation();
    startLoading();
    countQuery.refetch();
    fetch(0, data ? data.length : limit).then(() => {
      stopLoading();
    });
  };

  // Watch unread count
  useEffect(() => {
    const unreadCount = countQuery.data?.notificationsUnreadCount;

    // Fetch data when menu is open and a new unread notification has been created
    if (menu && unreadCount > count) {
      fetch(0, data ? data.length : limit);
    }

    setCount(countQuery.data?.notificationsUnreadCount);
  }, [countQuery.data?.notificationsUnreadCount]);

  return (
    <NotificationsContext.Provider
      value={{
        data,
        count,
        loading,
        error: query.error,
        loadMore,
        menu,
        toggleMenu,
        markRead,
        markReadAll,
        version,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
