import React, { useContext } from "react";
import { UserDto } from "../dtos/user.dto";
import { AuthContext } from "./auth.context";

export interface IOwnableDto {
  owner?: UserDto;
  partner?: UserDto;
}

interface PermissionContextProps {
  can(dto: IOwnableDto, query: string): boolean;
  canUser(dto: UserDto, query: string): boolean;
}

export const PermissionContext = React.createContext<PermissionContextProps>({
  can: () => false,
  canUser: () => false,
});

export const PermissionProvider: React.FC = ({ children }) => {
  const authContext = useContext(AuthContext);

  const can = (dto: IOwnableDto, query: string): boolean => {
    const [controller, action] = query.split(".");
    const permission = authContext.user?.role?.permissions?.find(
      (p) => p.controller === controller && p.action == action
    );

    if (permission?.enabled == true) {
      if (permission?.policy === "isowner") {
        return dto.owner?.id == authContext.userId;
      }

      if (permission?.policy === "isownerorpartner") {
        return (
          dto.owner?.id == authContext.userId ||
          dto.partner?.id == authContext.userId
        );
      }

      return true;
    }
    return false;
  };

  const canUser = (dto: UserDto, action: string): boolean => {
    const permission = authContext.user?.role?.permissions?.find(
      (p) => p.controller === "user" && p.action == action
    );

    return (
      permission?.enabled == true &&
      (permission?.policy === "isme" ? dto.id == authContext.userId : true)
    );
  };

  return (
    <PermissionContext.Provider value={{ can, canUser }}>
      {children}
    </PermissionContext.Provider>
  );
};
