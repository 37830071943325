import { MediaDto, mediaFields } from "./media.dto";
import { PostDto } from "./post.dto";
import { FollowerDto } from "./follower.dto";
import { RoleDto } from "./role.dto";
import { gql } from "@apollo/client";
import moment from "moment";
import { CasinoDto } from "./casino.dto";
import { BalanceConstraint } from "./balance-constraint.dto";
import { BonushuntReadDto } from "../pages/bonushunt/bonushunt.dto";
import { Dto as PartnershipDto } from "./partnership.dto";

export enum UserDtoType {
  Everyone = "",
  Member = "member",
  Streamer = "streamer",
  Partner = "partner",
}

export interface StreamerLiveStreamOfferDto {
  id?: string;
  title?: string;
  content?: string;
}

export interface StreamerBonusOfferDto {
  id?: string;
  title?: string;
  bonusOffer?: string;
  casino?: CasinoDto;
  url?: string;
  image?: MediaDto;
}

export enum StreamerLiveSourceEnum {
  TWITCH = "twitch",
  YOUTUBE = "youtube",
}

export interface UserLiveDto {
  live?: boolean;
  liveUpdated?: Date;
  liveSource?: StreamerLiveSourceEnum;
  liveId?: string;
  streamerLiveStreamOffers?: StreamerLiveStreamOfferDto[];
  offlineBanner?: MediaDto;
  twitchUsername?: string;
}

export interface UserDto extends UserLiveDto {
  id?: string;
  blocked?: boolean;
  slug?: string;
  role?: RoleDto;
  provider?: string;
  username?: string;
  profileName: string;
  email?: string;
  confirmed?: boolean;
  password?: string;
  type?: UserDtoType;
  lastOnline?: Date;
  views?: number;
  balance?: number;
  aboutMe?: string;
  websiteUrl?: string;
  twitchUsername?: string;
  youtubeUsername?: string;
  snapchatUsername?: string;
  twitterUsername?: string;
  facebookUsername?: string;
  instagramUsername?: string;
  streamSchedule?: string;
  highlightsPlaylistId?: string;

  notifyMentions?: boolean;
  notifyReplies?: boolean;
  notifyFollowers?: boolean;
  notifyScheduled?: boolean;
  notifyMarketingItems?: boolean;
  notifyPrizeWinner?: boolean;

  followers?: FollowerDto[];
  following?: FollowerDto[];

  posts?: PostDto[];

  avatar?: MediaDto;
  avatarPersonal?: MediaDto;
  banner?: MediaDto;

  bonushuntPage?: BonushuntReadDto;

  balanceConstraints?: BalanceConstraint[];
  streamerBonusOffers?: StreamerBonusOfferDto[];

  allowQuestions?: boolean;
  partnership?: PartnershipDto;
  isVerified?: boolean;
  birthday?: string;
  verificationStatus?: string;
}

export class UserDtoExtentions {
  constructor(readonly user?: UserDto) {}

  isOnline(): boolean {
    return this.user?.lastOnline
      ? Math.abs(moment().diff(moment(this.user?.lastOnline), "minutes")) < 5
      : false;
  }

  isStreamer(): boolean {
    return this.user?.type === UserDtoType.Streamer;
  }

  absoluteWebsitUrl(): string {
    const baseUrl = this?.user?.websiteUrl;

    return baseUrl?.startsWith("http") ? baseUrl : `http://${baseUrl}`;
  }
}

export const userFields = `
  id
  blocked
  type
  profileName
  slug
  avatar {
    ${mediaFields}
  }
  isVerified
`;

export const userSidebarFields = `
  ${userFields}
  balance

  twitchUsername
  youtubeUsername

  instagramUsername
  facebookUsername
  twitterUsername
  snapchatUsername
  followers(limit: 3) {
    id
    owner {
      ${userFields}
    }
  }
  streamerBonusOffers {
    id
    title
    casino {
      name
      logo {
        ${mediaFields}
      }
    }
    url
    bonusOffer
    image {
      ${mediaFields}
    }
  }
`;

export const meQuery = gql`
  query meQuery($id: ID!) {
    user(id: $id) {
      id
      blocked
      type
      balance
      profileName
      verificationStatus
      avatar {
        url
      }
      confirmed
      role {
        type
        permissions(
          where: { enabled: true, action_in: ["create", "update", "delete"] }
        ) {
          controller
          action
          id
          type
          policy
          enabled
        }
      }
      balanceConstraints {
        key
        lastRedeemed
      }
      partnership {
        id
      }
    }
  }
`;

export const loginMutation = gql`
  mutation loginMutation($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt
      user {
        id
      }
    }
  }
`;

export const updateUserMutation = gql`
  mutation updateUserMutation($input: updateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const emailExistsQuery = gql`
  query emailExistsQuery($value: String!) {
    users(where: { email_eq: $value }) {
      id
    }
  }
`;

export const profileNameExistsQuery = gql`
  query profileNameExistsQuery($value: String!) {
    users(where: { profileName_eq: $value }) {
      id
    }
  }
`;

export const PasswordStrengthPattern = `^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){1,})(?!.*\\s).{8,}$`;
