import React, { createContext } from "react";
import { QueryDto } from "../dtos/location.dto";
import { gql, useQuery } from "@apollo/client";

interface LocationContextInterface {
  data?: QueryDto;
}

export const LocationContext = createContext<LocationContextInterface>({});

export const LocationProvider: React.FC = ({ children }) => {
  const { data } = useQuery<QueryDto>(gql`
    query geoLocation {
      geoContentLocation {
        countryCode
        country
        configured
      }
    }
  `);

  return (
    <LocationContext.Provider value={{ data }}>
      {children}
    </LocationContext.Provider>
  );
};
