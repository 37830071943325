import React, { useEffect, useState } from "react";
import { Persistency } from "../utils/persistency";

export enum CookieProviderEnum {
  MATOMO = "matomo",
}

interface CookieConsentProviderInterface {
  allowed: boolean;
}

interface CookieConsentConfigurationInterface {
  date: Date;
  providers: { [index in CookieProviderEnum]?: CookieConsentProviderInterface };
}

interface CookieConsentContextInterface {
  config: CookieConsentConfigurationInterface | null | undefined;
  submitted: Date | null | undefined;
  flush: (config: CookieConsentConfigurationInterface) => boolean;
  configure: (provider: CookieProviderEnum, allowed: boolean) => Promise<void>;
  enableAll: () => CookieConsentConfigurationInterface;
}

export const CookieConsentContext =
  React.createContext<CookieConsentContextInterface>({
    config: undefined,
    submitted: undefined,
    flush: () => {
      return false;
    },
    configure: async () => {},
    enableAll: () => {
      return { date: new Date(), providers: {} };
    },
  });

export const CookieConsentProvider: React.FC = ({ children }) => {
  const [config, setConfig] = useState<
    CookieConsentConfigurationInterface | null | undefined
  >();
  const [submitted, setSubmitted] = useState<Date | null | undefined>();
  const persistencyKey = "cookie:consent";

  // Flushes current configuration to the persistent storage and updates submission
  const flush = (config: CookieConsentConfigurationInterface) => {
    setSubmitted(config.date);
    return Persistency.set(persistencyKey, JSON.stringify(config));
  };

  // Enables or disables one provider
  const configure = async (provider: CookieProviderEnum, allowed: boolean) => {
    setConfig({
      date: new Date(),
      providers: {
        ...config?.providers,
        [provider]: { allowed },
      },
    });
  };

  // Enables all providers
  const enableAll = () => {
    const config: CookieConsentConfigurationInterface = {
      date: new Date(),
      providers: {},
    };
    for (const provider of Object.values(CookieProviderEnum))
      config.providers[provider] = { allowed: true };
    setConfig(config);
    return config;
  };

  useEffect(() => {
    // Fetch and decode persisted configuration
    const raw = Persistency.get(persistencyKey);
    const data = raw ? JSON.parse(raw) : null;
    setConfig(data);

    // Set submission date
    setSubmitted(data?.date ? data.date : null);
  }, []);

  return (
    <CookieConsentContext.Provider
      value={{
        config,
        submitted,
        flush,
        configure,
        enableAll,
      }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
};
