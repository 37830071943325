import { gql } from "@apollo/client";
import { mediaFields } from "./media.dto";
import { UserDto } from "./user.dto";

export enum NotificationPrimaryEnum {
  COMMENT = "comment",
  REACTION = "reaction",
  FOLLOWER = "follower",
  SCHEDULED = "scheduled",
  MARKETING_ITEM = "marketingItem",
  LIVE_STREAM = "liveStream",
  PRIZE_WINNER = "prizeWinner",
  STREAMER = "streamer",
}

export enum NotificationSecondaryEnum {
  POST = "post",
  REPLY = "reply",
  LIKE = "like",
  APPROVED = "approved",
  REJECTED = "rejected",
  GIVEAWAY = "giveaway",
  PRIZE_DROP = "prizeDrop",
  INACTIVE = "inactive",
  CANCELLED = "cancelled",
}

export enum NotificationTargetReferenceEnum {
  POST = "post",
  PROFILE = "profile",
  PRIZE_WINNER = "prize-winner",
}

export interface NotificationDto {
  id: string;
  date: Date;
  primary: NotificationPrimaryEnum;
  secondary: NotificationSecondaryEnum;
  content?: string;
  targetRef?: NotificationTargetReferenceEnum;
  target?: string;
  user?: UserDto;
  count?: number;
  read: boolean;
}

export const notificationsQuery = gql`
    query NotificationsQuery($limit: Int, $start: Int) {
        notificationsCurrent(
            limit: $limit
            start: $start
        ) {
            id
            date
            primary
            secondary
            content
            targetRef
            target
            user {
                profileName
                avatar {
                    ${mediaFields}
                }
            }
            count
            read
        }
    }
`;

export const notificationMarkReadMutation = gql`
  mutation markRead($id: ID!) {
    notificationsMarkRead(id: $id)
  }
`;

export const notificationMarkReadAllMutation = gql`
  mutation markReadAll {
    notificationsMarkReadAll
  }
`;
