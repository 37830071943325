const uris = {
  localhost: "http://localhost:1337",
  'dev.casinoring.com': "http://localhost:1337",
  "casinoring.comceptum.dev": "https://casinoring-cms.comceptum.dev",
  "casinoring.com": "https://cms.casinoring.com",
};

const unrestrictedOperations = ["multipleUploadMutation"];

const unrestrictedUris = {
  localhost: "http://localhost:1337",
  'dev.casinoring.com': "http://localhost:1337",
  "casinoring.comceptum.dev": "https://casinoring-cms-upload.comceptum.dev",
  "casinoring.com": "https://upload.cms.casinoring.com",
};

const getHostName = () =>
  typeof window !== `undefined` ? location.hostname : "localhost";

export const apiBaseUrl = (operationName?: string): string => {
  const hostname = getHostName();

  return operationName && unrestrictedOperations.includes(operationName)
    ? unrestrictedUris[hostname]
    : uris[hostname];
};
