import React, { useEffect, useState } from "react";
import {
  ApolloClient,
  ApolloProvider,
  ApolloLink,
  InMemoryCache,
  Operation,
  NormalizedCacheObject,
} from "@apollo/client";
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { buildAxiosFetch } from "@lifeomic/axios-fetch";
import axios, { AxiosTransformer } from "axios";
import { extractFiles } from "extract-files";
import { apiBaseUrl } from "../utils/api";

const transformer = (config: any, input: any, init: any) => {
  return {
    ...config,
    onUploadProgress: init.onUploadProgress,
    cancelToken: init.cancelToken && init.cancelToken,
  };
};

// https://github.com/jaydenseric/apollo-upload-client/issues/34
const uploadAndBatchHTTPLink = (options) =>
  ApolloLink.split(
    (operation) => extractFiles(operation).files.size > 0,
    // @ts-ignore
    createUploadLink(options),
    new BatchHttpLink(options)
  );

const httpLink = uploadAndBatchHTTPLink({
  uri: (operation: Operation) =>
    `${apiBaseUrl(operation.operationName)}/graphql`,
  fetch: buildAxiosFetch(axios, transformer as AxiosTransformer) as any,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("auth:jwt");

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const useApolloClient = () => {
  const [apollo, setApollo] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    (async () => {
      const cache = new InMemoryCache();

      await persistCache({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
      });

      setApollo(
        new ApolloClient({
          link: authLink.concat(httpLink as any),
          cache,
          defaultOptions: {
            query: {
              fetchPolicy: "no-cache",
            },
            watchQuery: {
              fetchPolicy: "no-cache",
            },
          },
        })
      );
    })();
  }, []);

  return apollo;
};

export const GrapQLProvider: React.FC = ({ children }) => {
  const client = useApolloClient();

  if (!client) return null;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
