import React from "react";
import { AuthProvider } from "../context/auth.context";
import { PermissionProvider } from "../context/permission.context";
import { GrapQLProvider } from "../context/graphql.context";
import SnackbarProvider from "react-simple-snackbar";
import { LayoutProvider } from "../context/layout.context";
import { NotificationsProvider } from "../context/notifications.context";
import { MatomoProvider } from "../context/matomo.context";
import { RefreshProvider } from "../context/refresh.context";
import { FullScreenProvider } from "../context/full-screen.context";
import { LocationProvider } from "../context/location.context";
import { SeoProvider } from "../context/seo.context";
import { RegistrationProvider } from "../context/registration.context";

interface RootProps {
  element: React.ReactNode;
}

export const wrapRootElement: React.FC<RootProps> = ({ element }) => {
  return (
    <>
      <SnackbarProvider>
        <GrapQLProvider>
          <AuthProvider>
            <MatomoProvider>
              <PermissionProvider>
                <LayoutProvider>
                  <SeoProvider>
                    <FullScreenProvider>
                      <RefreshProvider>
                        <RegistrationProvider>
                          <NotificationsProvider>
                            <LocationProvider>{element}</LocationProvider>
                          </NotificationsProvider>
                        </RegistrationProvider>
                      </RefreshProvider>
                    </FullScreenProvider>
                  </SeoProvider>
                </LayoutProvider>
              </PermissionProvider>
            </MatomoProvider>
          </AuthProvider>
        </GrapQLProvider>
      </SnackbarProvider>
    </>
  );
};
