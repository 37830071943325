import React from "react";
import { LayoutProps } from "../components/layout";

interface ILayoutContext {
  props: LayoutProps;
  updateProps: (props: LayoutProps) => void;
}

export const LayoutContext = React.createContext<ILayoutContext>({
  props: {},
  updateProps: () => {},
});

export const LayoutProvider: React.FC = ({ children }) => {
  const [props, setProps] = React.useState<LayoutProps>({});

  const updateProps = (update: LayoutProps) =>
    setProps({ ...props, ...update });

  return (
    <LayoutContext.Provider value={{ props, updateProps }}>
      {children}
    </LayoutContext.Provider>
  );
};
