import React, { useEffect, useState } from "react";
import { globalHistory } from "@reach/router";

interface FullScreen {
  toggleMainMenu: () => void;
  enableMainMenu: () => void;
  disableMainMenu: () => void;
  toggleSidebarMenu: () => void;
  enableSidebarMenu: () => void;
  disableSidebarMenu: () => void;
  showMainMenu: boolean;
  showSidebarMenu: boolean;
  allowMainMenu: boolean;
  allowSidebarMenu: boolean;
}

export const FullScreenContext = React.createContext<FullScreen>({
  toggleMainMenu: () => {},
  enableMainMenu: () => {},
  disableMainMenu: () => {},
  toggleSidebarMenu: () => {},
  enableSidebarMenu: () => {},
  disableSidebarMenu: () => {},
  allowMainMenu: true,
  allowSidebarMenu: true,
  showMainMenu: true,
  showSidebarMenu: true,
});

export const FullScreenProvider: React.FC = ({ children }) => {
  const [mainMenu, setMainMenu] = useState(true);
  const [sideBarMenu, setSidebarMenu] = useState(true);

  const toggleMainMenu = () => setMainMenu(!mainMenu);
  const enableMainMenu = () => setMainMenu(true);
  const disableMainMenu = () => setMainMenu(false);
  const toggleSidebarMenu = () => setSidebarMenu(!sideBarMenu);
  const enableSidebarMenu = () => setSidebarMenu(true);
  const disableSidebarMenu = () => setSidebarMenu(false);

  const [allowMainMenu, setAllowMainMenu] = useState(true);
  const [allowSidebarMenu, setAllowSidebarMenu] = useState(true);

  const showMainMenu = mainMenu && allowMainMenu;
  const showSidebarMenu = sideBarMenu && allowSidebarMenu;

  useEffect(() => {
    const updateFeatures = () => {
      // Prevent new tab resizing
      if (document.hidden) return;
      setAllowMainMenu(window.outerWidth >= 1200);
      setAllowSidebarMenu(window.outerWidth >= 768);
    };

    // Initial update
    updateFeatures();

    window.addEventListener("resize", updateFeatures);
    return () => window.removeEventListener("resize", updateFeatures);
  }, []);

  // Re-open menu's on routing
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setMainMenu(true);
        setSidebarMenu(true);
      }
    });
  }, []);

  return (
    <FullScreenContext.Provider
      value={{
        toggleMainMenu,
        enableMainMenu,
        disableMainMenu,
        toggleSidebarMenu,
        enableSidebarMenu,
        disableSidebarMenu,
        showMainMenu,
        showSidebarMenu,
        allowMainMenu,
        allowSidebarMenu,
      }}
    >
      {children}
    </FullScreenContext.Provider>
  );
};
