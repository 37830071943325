import "bootstrap/dist/css/bootstrap.min.css";
import "react-mde/lib/styles/css/react-mde-all.css";
import "emoji-mart/css/emoji-mart.css";
import "@comceptum-software/react-image-lightbox/style.css";
import "animate.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-dropdown/style.css';

require("./src/styles/global.css");
require("./src/styles/notifications.css");
require("./src/styles/casino.css");
require("./src/styles/content-indicator.css");
require("./src/styles/wysiwyg.css");
require("./src/styles/bonus-offers.css");
require("./src/styles/progress-button.css");
require("./src/styles/video.css");
require("./src/styles/cookie-wall.css");
require("./src/styles/badges.css");
require("./src/styles/modal.css");
require("./src/styles/snackbar.css");
require("./src/styles/profile-slot-suggestion.css");
require("./src/styles/banner-container.css");
require("./src/styles/partner-question-form.css");
require("./src/styles/modal-marketing.css");
require("./src/styles/banner.css");
require("./src/styles/profile-livestream.css");
require("./src/styles/top-banner-slider.css");
require("./src/styles/profile-offline-banner.css");
require("./src/styles/streamers-carousel.css");
require("./src/styles/profile-offer.css");
require("./src/styles/moderator-filters.css");
require("./src/styles/demo-games.css");
require("./src/styles/giveaway-stepper.css");
require("./src/styles/react-slick.css");
require("./src/styles/language-selector.css");
require("./src/styles/about-menu.css");
require("./src/styles/veriff.css");
require("./src/styles/side-banner.css");
require("./src/styles/streamerslobby.css");

require("./src/components/settings-page/prize-drop-settings.scss");
require("./src/components/prize-drop/prize-drop.scss");

import "typeface-rubik";
import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.min";
import "bootstrap/js/dist/util";
import "bootstrap/js/dist/carousel";
import "bootstrap/js/dist/modal";

export { wrapRootElement } from "./src/components/root";
