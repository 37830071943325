export class Persistency {
  static get(key: string) {
    return localStorage.getItem(key);
  }

  static set(key: string, value: string | null) {
    if (value) {
      localStorage.setItem(key, value);

      return true;
    } else {
      return Persistency.remove(key);
    }
  }

  static remove(key: string) {
    localStorage.removeItem(key);

    return true;
  }
}
