import React, { useContext, useEffect, useState } from "react";
import { createInstance } from "@datapunt/matomo-tracker-react";
import { AuthContext } from "./auth.context";
import { globalHistory } from "@reach/router";
import { CookieConsentContext } from "./cookie-consent.context";
import { generateCustomVideoTrackingParameters } from "../utils/matomo";

export enum MatomoEventCategoryEnum {
  POST = "Post",
}

export enum MatomoEventActionEnum {
  LIKE = "Like",
}

export enum MatomoMediaType {
  VIDEO,
  AUDIO,
}

export interface MatomoMediaDimensions {
  width: number;
  height: number;
}

export interface MatomoMediaMeta {
  player?: string;
  title?: string;
  duration?: number;
  fullscreen?: boolean;
  dimensions?: MatomoMediaDimensions;
}

export interface MatomoMediaEvent {
  type: "audio" | "video";
  state: "play" | "pause";
  player: "post" | "youtubeLive" | "twitchLive";
  id: string;
  title?: string;
  url: string;
  progress: number;
  playbackTime: number;
  duration?: number;
  dimensions?: MatomoMediaDimensions;
  fullscreen: boolean;
  positions: number[];
}

export interface MatomoContextInterface {
  enableHeatmapSessionRecording: () => void;
  disableHeatmapSessionRecording: () => void;
  enableLinkTracking: () => void;
  trackEvent: (
    category: MatomoEventCategoryEnum,
    action: MatomoEventActionEnum,
    name: string
  ) => Promise<void>;
  trackMediaEvent: (event: MatomoMediaEvent) => void;
}

export const MatomoContext = React.createContext<MatomoContextInterface>({
  enableHeatmapSessionRecording: () => {},
  disableHeatmapSessionRecording: () => {},
  enableLinkTracking: () => {},
  trackEvent: async () => {},
  trackMediaEvent: async () => {},
});

export const MatomoProvider: React.FC = ({ children }) => {
  const authContext = useContext(AuthContext);
  const cookieConsentContext = useContext(CookieConsentContext);
  const [heatmapSessionRecording, setHeatmapSessionRecording] =
    useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  const urlBase = "https://casinoring.matomo.cloud";

  const siteId: Record<string, number> = {
    "https://casinoring.com": 1,
    "https://casinoring.comceptum.dev": 2,
  };

  const getSiteId = (origin: string) => {
    if (siteId[origin]) return siteId[origin];
    return 3; // Fallback to development site
  };

  const instance = createInstance({
    disabled: true, // Disable in favor of Google Analytics
    urlBase,
    siteId: getSiteId(window.location.origin),
    linkTracking: false,
    heartBeat: {
      active: true,
      seconds: 5,
    },
    configurations: {
      setSecureCookie: window.location.protocol === "https:",
      setRequestMethod: "POST",
      setDownloadExtensions: ["jpg", "jpeg", "png", "gif", "svg"],
    },
  });

  const enableHeatmapSessionRecording = () => setHeatmapSessionRecording(true);

  const disableHeatmapSessionRecording = () =>
    setHeatmapSessionRecording(false);

  const enableLinkTracking = () => instance.enableLinkTracking(true);

  const trackEvent = async (
    category: MatomoEventCategoryEnum,
    action: MatomoEventActionEnum,
    name: string
  ) => {
    instance.trackEvent({ category, action, name });
  };

  const trackMediaEvent = async (event: MatomoMediaEvent) => {
    navigator.sendBeacon(
      `${urlBase}/matomo.php`,
      generateCustomVideoTrackingParameters(
        event,
        getSiteId(window.location.origin),
        authContext.user?.profileName
      )
    );
  };

  useEffect(() => {
    // Determine state
    const current = !!authContext.user?.profileName;

    // Compare current with previous state
    if (current === authenticated) return;

    // Set visitor id
    if (current) {
      instance.pushInstruction("setUserId", authContext.user?.profileName);
    } else {
      instance.pushInstruction("resetUserId");
    }

    // Track page view, with new visitor settings
    instance.pushInstruction("appendToTrackingUrl", "new_visit=1");
    instance.trackPageView();
    instance.pushInstruction("appendToTrackingUrl", "");

    // Flush new state
    setAuthenticated(current);
  }, [authContext.user?.profileName]);

  // Handle Heatmap session recording state
  useEffect(() => {
    instance.pushInstruction(
      `HeatmapSessionRecording::${
        heatmapSessionRecording ? "enable" : "disable"
      }`
    );
  }, [heatmapSessionRecording]);

  // Push page switches
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        disableHeatmapSessionRecording(); // Disable heatmap session recording since the page is not "loaded"
        instance.trackPageView();
      }
    });
  }, []);

  // Handle cookie consent
  useEffect(() => {
    const { submitted, config } = cookieConsentContext;
    if (typeof submitted === "undefined") return;

    if (submitted && config?.providers.matomo?.allowed) {
      instance.pushInstruction("setCookieConsentGiven");
    } else {
      // Also removes currently present cookies
      instance.pushInstruction("requireCookieConsent");
    }
  }, [cookieConsentContext.submitted]);

  return (
    <MatomoContext.Provider
      value={{
        enableHeatmapSessionRecording,
        disableHeatmapSessionRecording,
        enableLinkTracking,
        trackEvent,
        trackMediaEvent,
      }}
    >
      {children}
    </MatomoContext.Provider>
  );
};
