import { gql } from "@apollo/client";

export interface MediaDto {
  id: string;
  url: string;
  formats?: {
    large?: MediaDtoFormat;
    medium?: MediaDtoFormat;
    small?: MediaDtoFormat;
    thumbnail?: MediaDtoFormat;
  };
  staging?: File;
  stagingPreview?: string;
  alternativeText?: string;
}

export interface MediaDtoFormat {
  url: string;
}

export const imagePreview = (file: File) => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) =>
      event.target?.result && resolve(event.target?.result as string);
    reader.readAsDataURL(file);
  });
};

export const formatOrUrl = (format: string, media?: MediaDto) => {
  const formats: any = media?.formats;
  return formats?.[format]?.url || media?.url;
};

export const mediaFields = `
  id
  url
  formats
  alternativeText
`;

export const multipleUploadMutation = gql`
  mutation multipleUploadMutation($files: [Upload]!) {
    multipleUpload(files: $files) {
      id
      mime
    }
  }
`;
