import React from "react";

interface ContentContextInterface {
  requestedRefresh: boolean;
  requestRefresh: () => Promise<void>;
  refreshed: () => Promise<void>;
}

export const ContentContext = React.createContext<ContentContextInterface>({
  requestedRefresh: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  requestRefresh: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refreshed: async () => {},
});

export const RefreshProvider: React.FC = ({ children }) => {
  const [requestedRefresh, setRequestedRefresh] =
    React.useState<boolean>(false);
  const requestRefresh = async () => setRequestedRefresh(true);
  const refreshed = async () => setRequestedRefresh(false);

  return (
    <ContentContext.Provider
      value={{ requestedRefresh, requestRefresh, refreshed }}
    >
      {children}
    </ContentContext.Provider>
  );
};
