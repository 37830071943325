import { useContext, useEffect, useRef } from "react";
import {
  MatomoContext,
  MatomoMediaEvent,
  MatomoMediaMeta,
} from "../context/matomo.context";
import { findCookieByName } from "./cookie";

const getCustomBaseParameters = (siteId: number, userId?: string) => {
  const date = new Date();
  const cookie = findCookieByName("_pk_id");

  return {
    idsite: siteId,
    ca: 1,
    rec: 1,
    send_image: 0,
    url: window.location.href,
    res: `${screen.width}x${screen.height}`,
    h: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
    ...(cookie ? { _id: cookie.split(".")[0] } : {}),
    ...(userId ? { uid: userId } : {}),
  };
};

const getCustomEventParameters = (
  category: string,
  action: string,
  name: string
) => ({
  e_c: category,
  e_a: action.charAt(0).toUpperCase() + action.slice(1),
  e_n: name,
});

const getCustomMediaParameters = (event: MatomoMediaEvent) => ({
  ma_id: event.id,
  ...(event.title ? { ma_ti: event.title } : {}),
  ma_re: event.url,
  ma_mt: event.type,
  ma_pn: event.player,
  ma_st: event.playbackTime,
  ...(event.duration ? { ma_le: event.duration } : {}),
  ma_ps: event.progress,
  ...(event.dimensions?.width ? { ma_w: event.dimensions.width } : {}),
  ...(event.dimensions?.height ? { ma_h: event.dimensions.height } : {}),
  ma_fs: event.fullscreen ? 1 : 0,
  ...(event.positions.length !== 0 ? { ma_se: event.positions.join(",") } : {}),
});

export const generateCustomVideoTrackingParameters = (
  event: MatomoMediaEvent,
  siteId: number,
  userId?: string
) => {
  const getEventName = (player: string) => {
    if (player === "youtubeLive") return "YoutubeLiveVideo";
    if (player === "twitchLive") return "TwitchLiveVideo";
    return "PostVideo";
  };

  const parameters = {
    ...getCustomBaseParameters(siteId, userId),
    ...getCustomEventParameters(
      "MediaVideo",
      event.state,
      getEventName(event.player)
    ),
    ...getCustomMediaParameters(event),
  };

  const data = new FormData();
  for (const key of Object.keys(parameters)) {
    data.append(key, parameters[key]);
  }
  return data;
};

export const generateId = () =>
  Math.round(Math.pow(36, 12 + 1) - Math.random() * Math.pow(36, 12))
    .toString(36)
    .slice(1);

export const useVideoTracking = (url: string) => {
  const timer = useRef<NodeJS.Timeout>();
  const { trackMediaEvent } = useContext(MatomoContext);

  const data = useRef<MatomoMediaEvent>({
    type: "video",
    state: "pause",
    player: "post",
    id: generateId(),
    url,
    progress: 0,
    playbackTime: 0,
    fullscreen: false,
    positions: [],
  });

  const playbackRate = useRef<number>(1);

  const updateTracking = (update: any) => {
    data.current = {
      ...data.current,
      ...update,
    };
  };

  const setMetaData = (meta: MatomoMediaMeta) => updateTracking(meta);

  const onPlay = () => {
    timer.current = setInterval(() => {
      updateTracking({
        state: "play",
        progress: data.current.progress + playbackRate.current,
        playbackTime: data.current.playbackTime + playbackRate.current,
      });
    }, 1000);
  };

  const onPause = () => {
    if (!timer.current) return;
    clearInterval(timer.current);
    updateTracking({ state: "pause" });
  };

  const onSeek = (position: number) =>
    updateTracking({
      progress: position,
      positions: [...data.current.positions, position],
    });

  const onRateChange = (rate: number) => (playbackRate.current = rate);

  useEffect(() => {
    trackMediaEvent(data.current);

    const push = setInterval(() => {
      trackMediaEvent(data.current);
    }, 5000);
    return () => clearInterval(push);
  }, []);

  return {
    setMetaData,
    onPlay,
    onPause,
    onSeek,
    onRateChange,
  };
};
